/**
 * @generated SignedSource<<27683b2fb63648bdd5b0507ca91c5048>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_growthRecommendationsInProjectList_RecommendationsInProjectList$data = {
  readonly projectListViewTemplates: {
    readonly nodes: ReadonlyArray<{
      readonly isProductLicensed: boolean;
      readonly key: string;
      readonly productKey: string;
      readonly templateType: string;
      readonly " $fragmentSpreads": FragmentRefs<"card_growthRecommendationsInProjectList_TemplateCard" | "preview_growthRecommendationsInProjectList_TemplatePreview">;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly userPreferences: {
    readonly __id: string;
  } | null | undefined;
  readonly " $fragmentType": "ui_growthRecommendationsInProjectList_RecommendationsInProjectList";
};
export type ui_growthRecommendationsInProjectList_RecommendationsInProjectList$key = {
  readonly " $data"?: ui_growthRecommendationsInProjectList_RecommendationsInProjectList$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_growthRecommendationsInProjectList_RecommendationsInProjectList">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "ui_growthRecommendationsInProjectList_RecommendationsInProjectList",
  "selections": [
    {
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "experimentKey",
          "value": "sidebarExperiment"
        }
      ],
      "concreteType": "JiraProjectListViewTemplateConnection",
      "kind": "LinkedField",
      "name": "projectListViewTemplates",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraProjectListViewTemplateItem",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "kind": "ScalarField",
                "name": "key"
              },
              "action": "THROW",
              "path": "projectListViewTemplates.nodes.key"
            },
            {
              "kind": "RequiredField",
              "field": {
                "kind": "ScalarField",
                "name": "templateType"
              },
              "action": "THROW",
              "path": "projectListViewTemplates.nodes.templateType"
            },
            {
              "kind": "RequiredField",
              "field": {
                "kind": "ScalarField",
                "name": "productKey"
              },
              "action": "THROW",
              "path": "projectListViewTemplates.nodes.productKey"
            },
            {
              "kind": "RequiredField",
              "field": {
                "kind": "ScalarField",
                "name": "isProductLicensed"
              },
              "action": "THROW",
              "path": "projectListViewTemplates.nodes.isProductLicensed"
            },
            {
              "kind": "FragmentSpread",
              "name": "card_growthRecommendationsInProjectList_TemplateCard"
            },
            {
              "kind": "FragmentSpread",
              "name": "preview_growthRecommendationsInProjectList_TemplatePreview"
            }
          ]
        }
      ]
    },
    {
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "JiraUserPreferences",
      "kind": "LinkedField",
      "name": "userPreferences",
      "plural": false,
      "selections": [
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "__id"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraQuery"
};
})();

(node as any).hash = "117947c2155195c861516e1daa9cbd39";

export default node;
